<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-19 23:10:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-25 16:22:32
-->


<template>
  <el-dialog
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="title"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="父级">
        <el-cascader
          :options="deptList"
          v-model="form.fatherIds"
          :props="{
            label: 'name',
            value: 'id',
            checkStrictly: true,
          }"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="负责人">
        <el-select
          v-model="form.master_id"
          remote
          clearable
          filterable
          reserve-keyword
          placeholder="请输入姓名、手机号搜索"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in empOptions"
            :key="item.id"
            :label="item.chs_name"
            :value="item.id"
          >
            {{ item.chs_name }}{{ item.mobile_phone }}
          </el-option>
        </el-select>
        <div class="help-block">
          <el-icon><WarningFilled /></el-icon>搜索后需选择相应人员
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button
        type="primary"
        @click="onSubmit"
        :disabled="loading"
        :loading="loading"
        >确定</el-button
      >
      <el-button @click="cancelClick" :disabled="loading">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      title: "",
      dialogVisible: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
      //搜索到的人员
      empOptions: [],
    };
  },
  props: ["item", "reload", "deptList"],
  watch: {
    item: {
      handler() {
        this.form = {};
        if (this.item) {
          this.dialogVisible = true;
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
            if (this.item.master) {
              this.empOptions = [this.item.master];
            }
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/seller/v1/dept/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.dialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.dialogVisible = false;
      if (this.reload) {
        this.reload();
      }
    },

    /**
     * 搜索负责人
     */
    remoteMethod(q) {
      this.loading = true;
      this.$http.get("seller/v1/emp/select?q=" + q).then((res) => {
        if (res.code == 0) {
          this.empOptions = res.data;
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style   scoped>

</style>







