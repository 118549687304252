<!--
 * @Description: 部门
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:35:58
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-13 11:25:10
-->
<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus" v-if="is_can_edit"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData" border row-key="id" default-expand-all>
      <el-table-column label="名称" prop="name"></el-table-column>

      <el-table-column label="部门主管" width="300">
        <template #default="scope">
          <span v-if="scope.row.master_id">
            {{ scope.row.master.chs_name }}{{ scope.row.master.mobile_phone }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180">
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)" v-if="is_can_edit"
            >修改</el-button
          >
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)" v-if="is_can_del">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-dept
    :item="current"
    :reload="loadData"
    :deptList="tableData"
  ></edit-dept>
</template>
<script>
import dept_api from "../../../http/dept_api";
import EditDept from "./edit_dept.vue";

export default {
  components: {
    EditDept,
  },
  data() {
    return {
      loading: true,
      tableData: [],
      current: null,
      is_can_edit:false,
      is_can_del:false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;

      dept_api.get_dept().then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });

      this.is_can_edit = this.$power('seller/v1/dept/edit');
    this.is_can_del = this.$power('seller/v1/dept/del');
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      if (item) {
        this.recursion(item);
      }

      this.current = item ? item : {};
    },

    /**
     * 递归
     * @param {*} item
     */
    recursion(item) {
      item.disabled = true;
      item.children.forEach((x) => {
        this.recursion(x);
      });
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/dept/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>